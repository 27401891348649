
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "auto"
    },
    colspan: {
      type: Number,
      default: 1
    },
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    }
  }
});
