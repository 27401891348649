<template>
  <div class="dropdown">
    <select
      v-model="selection"
      @change="$emit('update:value', $event.target.value)"
      id="id"
    >
      <option :value="null" disabled>Select a language</option>
      <option v-for="(option, key) in options" :key="key" :value="key">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    id: {
      type: String
    }
  },
  setup(props) {
    const selection = ref(null as null | string);

    watch(props, () => {
      selection.value = props.value;
    });

    return { selection };
  }
});
</script>

<style lang="scss" scoped>
select {
  width: 100%;
  background-color: transparent;
  padding: 9px 18px;
  border: 1px solid #e5e8f5;
  border-radius: 6px;
  color: #323a49;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border: 1px solid #7396fc;
  }
}
</style>
