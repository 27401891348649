<template>
  <div class="language-block">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.language-block {
  background: linear-gradient(180deg, #89a7ff 0%, #7396fc 100%);
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  width: 38px;
  height: 34px;
  text-transform: uppercase;
}
</style>
