<template>
  <form @submit.prevent="$emit('submit')">
    <slot />
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["submit"]
});
</script>

<style lang="scss" scoped>
form {
  width: 100%;
  max-width: 600px;
}
</style>
