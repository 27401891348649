<template>
  <td :class="{ md, lg }" :style="{ width }" :colspan="colspan"><slot /></td>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "auto"
    },
    colspan: {
      type: Number,
      default: 1
    },
    md: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
td {
  color: #323a49;
  font-size: 18px;
  vertical-align: middle;

  &:first-child {
    @media (min-width: 768px) {
      padding-left: 30px;
    }
  }

  &:last-child {
    @media (min-width: 768px) {
      padding-left: 30px;
    }
  }

  // @media (max-width: 700px) {
  //   display: block;
  // }

  &.md {
    display: none;
    @media (min-width: 768px) {
      display: table-cell;
    }
  }

  &.lg {
    display: none;
    @media (min-width: 1200px) {
      display: table-cell;
    }
  }
}
</style>
