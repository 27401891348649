
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: true
    }
  }
});
