<template>
  <div class="create-word-list">
    <div class="title">
      <Title>Create word list</Title>
    </div>
    <Form @submit="handleSubmit">
      <FormItem>
        <Label forId="name">Name</Label>
        <TextBox name="name" id="name" v-model:value="fields.name" />
      </FormItem>
      <FormItem>
        <Label forId="language">Language</Label>
        <Dropdown
          id="language"
          v-model:value="fields.language"
          :options="{
            en: 'English',
            pl: 'Polish',
            nl: 'Dutch',
            fr: 'French',
            de: 'German',
            hi: 'Hindi',
            it: 'Italian',
            es: 'Spanish',
            tr: 'Turkish',
            vi: 'Vietnamese',
            uk: 'Ukrainian',
            ar: 'Arabic',
            bn: 'Bengali',
            yue: 'Chinese',
            cs: 'Czech',
            da: 'Danish',
            sv: 'Swedish',
            nb: 'Norwegian'
          }"
        />
      </FormItem>
      <AppButton type="submit">Create</AppButton>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { Form, FormItem, Label, TextBox, Dropdown } from "@/components/form";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Form,
    FormItem,
    Label,
    TextBox,
    Dropdown
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fields = reactive({
      name: "",
      language: null
    });

    return {
      fields,
      async handleSubmit() {
        if (fields.name === "" || fields.language == null) return;

        await store.dispatch("CREATE_WORD_LIST", fields);
        router.push("/");
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 50px;
}
</style>
