<template>
  <header class="header">
    <nav class="menu">
      <router-link to="/" class="menu-item">HOME</router-link>
    </nav>
    <!-- <AppButton>Create List</AppButton> -->
  </header>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.menu-item {
  text-decoration: none;
  text-transform: uppercase;
  color: #6e7287;

  &:hover {
    color: darken(#6e7287, 30%);
  }
}
</style>
