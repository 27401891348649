<template>
  <div class="page-header">
    <Title><slot /></Title>
    <div v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.page-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 60px;
  margin-top: -10px;
}
</style>
