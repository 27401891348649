
import { defineComponent } from "vue";
import Header from "@/components/layout/Header.vue";

export default defineComponent({
  components: {
    Header
  },
  setup() {
    return {};
  }
});
