<template>
  <div class="table-wrapper">
    <Table :headers="['', 'Name']" :md-headers="['', 'Name', 'Words', '']">
      <TableRow
        v-for="wordList in wordLists"
        :key="wordList.id"
        @click="editList(wordList.id)"
      >
        <TableItem width="80px">
          <LanguageBlock>{{ wordList.language }}</LanguageBlock>
        </TableItem>
        <TableItem>
          {{ wordList.name }}
        </TableItem>
        <TableItem md>
          {{ wordList.words.length }}
        </TableItem>
        <TableItem width="220px" md>
          <!-- <div class="buttons"> -->
          <ButtonGroup right>
            <AppButton
              @click="practiceList(wordList.id)"
              v-if="wordList.words.length > 0"
              >Practice</AppButton
            >
            <AppButton @click="editList(wordList.id)">Edit</AppButton>
          </ButtonGroup>
          <!-- </div> -->
        </TableItem>
      </TableRow>
      <TableRow>
        <TableItem :colspan="4">
          <a @click="routeToCreateList" class="add-new">
            + Create new word list
          </a>
        </TableItem>
      </TableRow>
    </Table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Table, TableItem, TableRow } from "@/components/table";
import { mapState } from "vuex";
import { State } from "@/store";

export default defineComponent({
  components: {
    Table,
    TableItem,
    TableRow
  },
  computed: {
    ...mapState({
      wordLists: (state: any) => state.wordLists
    })
  },
  methods: {
    editList(id: string) {
      this.$router.push(`/edit/${id}`);
    },
    practiceList(id: string) {
      this.$router.push(`/practice/${id}`);
    },
    routeToCreateList() {
      this.$router.push("/create");
    }
  }
});
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 100px;
}

.add-new {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: #9aa1b7;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.02em;

  &:hover {
    color: #6e7287;
  }
}

.buttons {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}
</style>
