<template>
  <div class="form-item">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.form-item {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 15px;
}
</style>
