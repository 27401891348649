<template>
  <div class="edit-word-list">
    <PageHeader>
      <LanguageBlock>
        {{ wordList.language }}
      </LanguageBlock>
      <span class="text">
        {{ wordList.name }}
      </span>
      <template v-slot:right>
        <AppButton
          @click="$router.push('/practice/' + wordList.id)"
          v-if="wordList.words.length > 0"
          >Practice</AppButton
        >
      </template>
    </PageHeader>
    <Table :headers="['Image', 'Word', '']">
      <TableRow v-for="word in wordList.words" :key="word.id">
        <TableItem width="120px">
          <ImageItem :src="word.imgUrl" />
        </TableItem>
        <TableItem>
          {{ word.text }}
        </TableItem>
        <TableItem width="200px">
          <ButtonGroup right>
            <AppButton @click="editWord(word)">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="edit"
                class="svg-inline--fa fa-edit fa-w-18"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                width="16px"
                height="16px"
              >
                <path
                  fill="currentColor"
                  d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                ></path>
              </svg>
            </AppButton>
            <AppButton danger @click="removeWord(word.id)">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="trash"
                class="svg-inline--fa fa-trash fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="16px"
                height="16px"
              >
                <path
                  fill="currentColor"
                  d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                ></path>
              </svg>
              <!-- <span class="small">Remove</span> -->
              <!-- <span class="medi" -->
            </AppButton>
          </ButtonGroup>
        </TableItem>
      </TableRow>
      <TableRow>
        <TableItem :colspan="4">
          <a @click="showCreateWord" class="add-new">
            + Add new word
          </a>
        </TableItem>
      </TableRow>
    </Table>

    <Popup title="Add a new word" :active="popupVisible" @hide="hideCreate">
      <Form @submit="updateOrCreateWord">
        <FormItem>
          <Label forId="word">Word</Label>
          <TextBox name="word" id="word" v-model:value="wordFields.text" />
        </FormItem>
        <FormItem>
          <Label forId="image_url">Image URL</Label>
          <TextBox
            name="image_url"
            id="image_url"
            v-model:value="wordFields.imgUrl"
          />
        </FormItem>
        <FormItem>
          <Label forId="suggestions">Suggestions (experimental)</Label>
          <AppButton
            v-if="wordFields.text.length >= 3 && currentSuggestions == null"
            small
            @click="suggestImages"
            >Suggest images</AppButton
          >
          <div
            class="suggestions"
            id="suggestions"
            v-if="currentSuggestions != null"
          >
            <img
              v-for="suggestion in currentSuggestions"
              :key="suggestion.tiny"
              class="suggestion"
              :src="suggestion.tiny"
              @click="wordFields.imgUrl = suggestion.full"
              :class="{ selected: wordFields.imgUrl === suggestion.full }"
            />
          </div>
        </FormItem>
        <div class="buttons">
          <AppButton type="submit">
            <span v-if="wordFields.id == null">Create</span>
            <span v-else>Update</span>
          </AppButton>
        </div>
      </Form>
    </Popup>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import { Table, TableRow, TableItem, ImageItem } from "@/components/table";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Popup from "@/components/popup/Popup.vue";
import { Form, FormItem, Label, TextBox } from "@/components/form";
import Swal from "sweetalert2";
import { Word, WordList } from "@/store";
import imager from "@/util/imager";

export default defineComponent({
  components: {
    Table,
    TableRow,
    TableItem,
    ImageItem,
    Popup,
    Form,
    FormItem,
    Label,
    TextBox
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const wordList = store.getters.WORD_LIST(route.params.id) as WordList;

    const popupVisible = ref(false);
    const wordFields = reactive({
      id: null as null | string,
      text: "",
      imgUrl: ""
    });

    const suggestions = ref(
      {} as { [word: string]: { full: string; tiny: string }[] }
    );
    const currentSuggestions = computed(
      () => suggestions.value[wordFields.text] || null
    );

    return {
      popupVisible,
      wordFields,
      wordList,
      suggestions,
      currentSuggestions,
      async suggestImages() {
        const response = await imager.search(
          wordFields.text,
          wordList.language,
          5
        );
        const sugg = response.photos.map((photo: any) => ({
          full: photo.src.portrait,
          tiny: photo.src.tiny
        }));
        console.log(suggestions);
        suggestions.value[wordFields.text] = sugg;
      },
      showCreateWord() {
        wordFields.text = "";
        wordFields.imgUrl = "";
        wordFields.id = null;
        popupVisible.value = true;
      },
      async updateOrCreateWord() {
        if (wordFields.text === "" || wordFields.imgUrl === "") return;
        if (wordFields.id == null) {
          await store.dispatch("CREATE_WORD", {
            listId: route.params.id,
            text: wordFields.text,
            imgUrl: wordFields.imgUrl
          });
        } else {
          await store.dispatch("UPDATE_WORD", {
            listId: route.params.id,
            wordId: wordFields.id,
            text: wordFields.text,
            imgUrl: wordFields.imgUrl
          });
        }
        popupVisible.value = false;
      },
      async editWord(word: Word) {
        wordFields.id = word.id;
        wordFields.text = word.text;
        wordFields.imgUrl = word.imgUrl;
        popupVisible.value = true;
      },
      async removeWord(id: string) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to remove this word from the list",
          icon: "warning",
          confirmButtonText: "Yes, remove it",
          denyButtonText: "No, keep it",
          showDenyButton: true
        });
        if (result.isConfirmed) {
          store.dispatch("REMOVE_WORD", {
            listId: route.params.id,
            wordId: id
          });
        }
      },
      hideCreate() {
        popupVisible.value = false;
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.add-new {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  color: #9aa1b7;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.02em;

  &:hover {
    color: #6e7287;
  }
}

.text {
  margin-left: 15px;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-top: 30px;
}

.suggestions {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
}

.suggestion {
  max-width: 100%;
  width: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &.selected {
    outline: 5px solid #7396fc;
  }
}
</style>
