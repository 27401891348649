
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    type: {
      type: String,
      default: "button"
    },
    danger: {
      type: Boolean,
      default: false
    },
    radiusLeft: {
      type: Boolean,
      default: true
    },
    radiusRight: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    return {
      handleClick() {
        emit("click");
      }
    };
  }
});
