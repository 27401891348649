<template>
  <div class="container">
    <Header />
    <main>
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/layout/Header.vue";

export default defineComponent({
  components: {
    Header
  },
  setup() {
    return {};
  }
});
</script>

<style lang="scss" scoped>
.container {
  width: 85%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

main {
  margin-top: 50px;

  @media (min-width: 768px) {
    margin-top: 100px;
  }
}
</style>
