
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String
    },
    id: {
      type: String
    },
    value: {
      type: String,
      default: ""
    },
    radiusLeft: {
      type: Boolean,
      default: true
    },
    radiusRight: {
      type: Boolean,
      default: true
    }
  }
});
