import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style/app.scss";

import registerCommon from "@/components/common/register";

const app = createApp(App)
  .use(router)
  .use(store)

registerCommon(app);

app.mount("#app");
