
export interface Sound {
  type: "native" | "audio",
  data: any
}

function getNativeVoices(lang: string) {
  const voices = speechSynthesis.getVoices()
  const voicesInLang = voices.filter(voice => {
    voice.lang.toLocaleLowerCase().includes(lang.toLocaleLowerCase());
  })
  return voicesInLang;
}

function sayNative(voice: SpeechSynthesisVoice, lang: string, text: string): Sound {
  const msg = new SpeechSynthesisUtterance();
  msg.voice = voice;
  msg.volume = 1;
  msg.text = text;
  msg.lang = lang;
  return {
    type: "native",
    data: msg
  };
}

async function fetchFromAPI(lang: string, text: string): Promise<Sound> {
  // return new Promise(resolve => {
    const baseUrl = "https://teacher.invacto.com";
  // const baseUrl = "http://localhost:4000"
  const url = `${baseUrl}/say/${lang}/${encodeURI(text)}`;
  const audio = new Audio(url)
  audio.load();
  return {
    type: "audio",
    data: audio
  }
    // audio.play();
    // console.log(audio, url);
    // audio.onload = () => {
    //   console.log("Finshed");
    //   audio.play();
    //   resolve({
    //     type: "audio",
    //     data: audio
    //   })
    // }
    // audio.load();
  // });
}

export default {
  async load(lang: string, text: string): Promise<Sound> {
    const nativeVoices = getNativeVoices(lang);
    if (nativeVoices.length === 0) {
      return await fetchFromAPI(lang, text);
    }
    return sayNative(nativeVoices[0], lang, text);
  },
  say(sound: Sound) {
    if (sound.type === "audio") {
      sound.data.play();
    }
    if (sound.type === "native") {
      speechSynthesis.speak(sound.data);
    }
  }
}
