<template>
  <img :src="src" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
img {
  background-color: #e5e8f5;
  width: 65px;
  height: 55px;
  border-radius: 4px;
}
</style>
