<template>
  <tr @click.stop="$emit('click')" :class="{ clickable }">
    <slot />
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    clickable: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss" scoped>
tr {
  height: 80px;
  border-top: 1px solid #e5e8f5;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: #e5e8f5;
  }
}
</style>
