
import { defineComponent, reactive } from "vue";
import { Form, FormItem, Label, TextBox, Dropdown } from "@/components/form";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Form,
    FormItem,
    Label,
    TextBox,
    Dropdown
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const fields = reactive({
      name: "",
      language: null
    });

    return {
      fields,
      async handleSubmit() {
        if (fields.name === "" || fields.language == null) return;

        await store.dispatch("CREATE_WORD_LIST", fields);
        router.push("/");
      }
    };
  }
});
