import { App } from "vue";
import Button from "./Button.vue";
import LanguageBlock from "./LanguageBlock.vue";
import ButtonGroup from "./ButtonGroup.vue";
import Title from "./Title.vue";
import PageHeader from "./PageHeader.vue";

export default (app: App) => {
  app.component("AppButton", Button);
  app.component("ButtonGroup", ButtonGroup);
  app.component("LanguageBlock", LanguageBlock);
  app.component("Title", Title);
  app.component("PageHeader", PageHeader);
}
