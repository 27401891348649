<template>
  <table>
    <thead>
      <tr
        class="small"
        :class="{
          'has-medium': mdHeaders != null,
          'has-large': lgHeaders != null
        }"
      >
        <th v-for="headerItem in headers" :key="headerItem">
          {{ headerItem }}
        </th>
      </tr>
      <tr
        class="medium"
        :class="{
          'has-large': lgHeaders != null
        }"
      >
        <th v-for="headerItem in mdHeaders" :key="headerItem">
          {{ headerItem }}
        </th>
      </tr>
      <tr class="large">
        <th v-for="headerItem in lgHeaders" :key="headerItem">
          {{ headerItem }}
        </th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    },
    mdHeaders: {
      type: Array,
      default: null
    },
    lgHeaders: {
      type: Array,
      default: null
    }
    // empty: {
    //   type: Boolean,
    //   default: false
    // }
  }
});
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  @media (min-width: 768px) {
    margin-left: -30px;
    margin-right: -30px;
  }
}

th {
  text-align: left;
  color: #9aa1b7;
  font-size: 16px;
  padding-bottom: 20px;

  &:first-child {
    @media (min-width: 768px) {
      padding-left: 30px;
    }
  }

  &:last-child {
    @media (min-width: 768px) {
      padding-left: 30px;
    }
  }
}

.small {
  &.has-medium {
    @media (min-width: 768px) {
      display: none;
    }
  }
  &.has-large {
    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.medium {
  @media (max-width: 768px) {
    display: none;
    // display: table-cell;
  }
  &.has-large {
    @media (min-width: 1200px) {
      display: none;
    }
  }
}

.large {
  @media (max-width: 1200px) {
    display: none;
    // display: table-cell;
  }
}
</style>
