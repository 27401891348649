<template>
  <button
    :type="type"
    @click.stop="handleClick"
    class="button"
    :class="{
      danger,
      'radius-right': radiusRight,
      'radius-left': radiusLeft,
      small
    }"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  props: {
    type: {
      type: String,
      default: "button"
    },
    danger: {
      type: Boolean,
      default: false
    },
    radiusLeft: {
      type: Boolean,
      default: true
    },
    radiusRight: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    return {
      handleClick() {
        emit("click");
      }
    };
  }
});
</script>

<style lang="scss" scoped>
.button {
  background: linear-gradient(180deg, #89a7ff 0%, #7396fc 100%);
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  color: white;
  // border-radius: 4px;
  cursor: pointer;
  max-height: 100%;

  &:hover {
    background: darken(#7396fc, 10%);
  }
}

.small {
  padding: 6px 12px;
  font-size: 14px;
}

.radius-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.radius-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.danger {
  background: #f55f4b;

  &:hover {
    background: darken(#f55f4b, 20%);
  }
}
</style>
