<template>
  <label :for="forId"><slot /></label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    forId: {
      type: String,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
label {
  color: #9aa1b7;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
}
</style>
