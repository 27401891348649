<template>
  <h1><slot /></h1>
</template>

<style lang="scss" scoped>
h1 {
  font-size: 48px;
  display: flex;
  align-items: center;
}
</style>
