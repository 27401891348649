<template>
  <div class="popup" v-show="active">
    <div class="background" @click="$emit('hide')"></div>
    <div class="content">
      <h1 class="title" v-if="title != null">{{ title }}</h1>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#323a49, 0.7);
}

.content {
  margin-top: 150px;
  width: 90%;
  max-width: 600px;
  background-color: white;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  padding: 35px;
  box-sizing: border-box;
}

.title {
  font-size: 38px;
  padding-bottom: 40px;
  color: #323a49;
}
</style>
