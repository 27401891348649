import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { v4 as uuidv4 } from "uuid";

export interface WordList {
  id: string;
  name: string;
  language: string;
  words: Word[];
}

export interface Word {
  id: string;
  text: string;
  imgUrl: string;
}

export interface State {
  wordLists: WordList[];
}

const store = createStore({
  state(): State {
    return {
      wordLists: []
    };
  },
  getters: {
    WORD_LIST(state: State) {
      return (id: string) => {
        return state.wordLists.find(wl => wl.id === id);
      };
    }
  },
  mutations: {
    ADD_WORD_LIST(state: State, wordList: WordList) {
      state.wordLists.push(wordList);
    },
    ADD_WORD(state: State, { listId, word }) {
      const list = state.wordLists.find(wl => wl.id === listId);
      if (list == null) return;
      list.words.push(word);
    },
    REMOVE_WORD(state: State, { listId, wordId }) {
      const list = state.wordLists.find(wl => wl.id === listId);
      if (list == null) return;
      list.words = list.words.filter(w => w.id !== wordId);
    },
    UPDATE_WORD(state: State, { listId, wordId, text, imgUrl }) {
      const list = state.wordLists.find(wl => wl.id === listId);
      if (list == null) return;
      const word = list.words.find(w => w.id === wordId);
      if (word == null) return;
      word.text = text;
      word.imgUrl = imgUrl;
    }
  },
  actions: {
    CREATE_WORD_LIST({ commit }, { name, language }) {
      const wordList: WordList = {
        id: uuidv4(),
        name,
        language,
        words: []
      };
      commit("ADD_WORD_LIST", wordList);
    },
    CREATE_WORD({ commit }, { listId, text, imgUrl }) {
      const word: Word = {
        id: uuidv4(),
        imgUrl,
        text
      };
      commit("ADD_WORD", { listId, word });
    },
    UPDATE_WORD({ commit }, { listId, wordId, text, imgUrl }) {
      commit("UPDATE_WORD", { listId, wordId, text, imgUrl })
    },
    REMOVE_WORD({ commit }, { listId, wordId }) {
      commit("REMOVE_WORD", { listId, wordId })
    }
  },
  plugins: [createPersistedState()]
});

export default store;
