
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: String,
      default: null
    },
    id: {
      type: String
    }
  },
  setup(props) {
    const selection = ref(null as null | string);

    watch(props, () => {
      selection.value = props.value;
    });

    return { selection };
  }
});
