<template>
  <div class="wrapper">
    <input
      :type="type"
      :name="name"
      :id="id"
      :value="value"
      @input="$emit('update:value', $event.target.value)"
      :class="{ 'radius-left': radiusLeft, 'radius-righ': radiusRight }"
    />
    <div class="right" v-if="$slots.right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["update:value"],
  props: {
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String
    },
    id: {
      type: String
    },
    value: {
      type: String,
      default: ""
    },
    radiusLeft: {
      type: Boolean,
      default: true
    },
    radiusRight: {
      type: Boolean,
      default: true
    }
  }
});
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  background-color: transparent;
  padding: 11.5px 18px;
  border: 1px solid #e5e8f5;
  color: #323a49;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;

  &:focus {
    border: 1px solid #7396fc;
  }
}

.wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radius-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.radius-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
