
import { computed, defineComponent, reactive, ref } from "vue";
import { Table, TableRow, TableItem, ImageItem } from "@/components/table";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Popup from "@/components/popup/Popup.vue";
import { Form, FormItem, Label, TextBox } from "@/components/form";
import Swal from "sweetalert2";
import { Word, WordList } from "@/store";
import imager from "@/util/imager";

export default defineComponent({
  components: {
    Table,
    TableRow,
    TableItem,
    ImageItem,
    Popup,
    Form,
    FormItem,
    Label,
    TextBox
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const wordList = store.getters.WORD_LIST(route.params.id) as WordList;

    const popupVisible = ref(false);
    const wordFields = reactive({
      id: null as null | string,
      text: "",
      imgUrl: ""
    });

    const suggestions = ref(
      {} as { [word: string]: { full: string; tiny: string }[] }
    );
    const currentSuggestions = computed(
      () => suggestions.value[wordFields.text] || null
    );

    return {
      popupVisible,
      wordFields,
      wordList,
      suggestions,
      currentSuggestions,
      async suggestImages() {
        const response = await imager.search(
          wordFields.text,
          wordList.language,
          5
        );
        const sugg = response.photos.map((photo: any) => ({
          full: photo.src.portrait,
          tiny: photo.src.tiny
        }));
        console.log(suggestions);
        suggestions.value[wordFields.text] = sugg;
      },
      showCreateWord() {
        wordFields.text = "";
        wordFields.imgUrl = "";
        wordFields.id = null;
        popupVisible.value = true;
      },
      async updateOrCreateWord() {
        if (wordFields.text === "" || wordFields.imgUrl === "") return;
        if (wordFields.id == null) {
          await store.dispatch("CREATE_WORD", {
            listId: route.params.id,
            text: wordFields.text,
            imgUrl: wordFields.imgUrl
          });
        } else {
          await store.dispatch("UPDATE_WORD", {
            listId: route.params.id,
            wordId: wordFields.id,
            text: wordFields.text,
            imgUrl: wordFields.imgUrl
          });
        }
        popupVisible.value = false;
      },
      async editWord(word: Word) {
        wordFields.id = word.id;
        wordFields.text = word.text;
        wordFields.imgUrl = word.imgUrl;
        popupVisible.value = true;
      },
      async removeWord(id: string) {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to remove this word from the list",
          icon: "warning",
          confirmButtonText: "Yes, remove it",
          denyButtonText: "No, keep it",
          showDenyButton: true
        });
        if (result.isConfirmed) {
          store.dispatch("REMOVE_WORD", {
            listId: route.params.id,
            wordId: id
          });
        }
      },
      hideCreate() {
        popupVisible.value = false;
      }
    };
  }
});
