<template>
  <div class="button-group" :class="{ 'button-group--right': right }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    right: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="scss">
.button-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &--right {
    justify-content: flex-end;
  }
}

.button-group .button {
  border-radius: 0;
  margin-right: 5px;

  &:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 0;
  }
}
</style>
