import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WordLists from "../views/WordLists.vue";
import CreateWordList from "../views/CreateWordList.vue";
import EditWordList from "../views/EditWordList.vue";
import PracticeWordList from "../views/PracticeWordList.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "WordLists",
    component: WordLists
  },
  {
    path: "/create",
    name: "CreateWordList",
    component: CreateWordList
  },
  {
    path: "/edit/:id",
    name: "EditWordList",
    component: EditWordList
  },
  {
    path: "/practice/:id",
    name: "PracticeWordList",
    component: PracticeWordList
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
