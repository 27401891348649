<template>
  <div v-if="currentWord == null" class="wrapper">
    <AppButton @click="nextWord">Start</AppButton>
    <div class="description">
      <p>
        Arrow Right -> Next word
      </p>
      <p>
        Arrow Left -> Play sound
      </p>
    </div>
  </div>
  <div v-else class="wrapper">
    <img :src="currentWord.imgUrl" @click="nextWord" />
    <div class="buttons">
      <AppButton @click="playSound">Play</AppButton>
      <AppButton @click="nextWord">Next</AppButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Word, WordList } from "@/store";
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import tts, { Sound } from "@/util/tts";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();

    const currentWord = ref(null as null | Word);
    const currentSound = ref(null as null | Sound);
    const wordList = store.getters.WORD_LIST(route.params.id) as WordList;

    let tout = null as null | number;

    function randomWord(): Word {
      if (wordList.words.length <= 1) return wordList.words[0];
      const randomIndex = Math.floor(Math.random() * wordList.words.length);
      const word = wordList.words[randomIndex];
      if (currentWord.value != null && word.id === currentWord.value.id) {
        return randomWord();
      }
      return word;
    }

    function playSound() {
      if (currentSound.value == null) return;
      if (tout != null) clearTimeout(tout);
      tts.say(currentSound.value);
    }

    async function nextWord() {
      const word = randomWord();
      currentWord.value = word;
      currentSound.value = await tts.load(wordList.language, word.text);

      if (tout != null) clearTimeout(tout);
      tout = setTimeout(playSound, 1000 + currentWord.value.text.length * 150);
    }

    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === "ArrowRight") {
        nextWord();
      }
      if (e.key === "ArrowLeft") {
        playSound();
      }
    }

    onMounted(() => {
      console.log("Test");
      document.addEventListener("keydown", handleKeyDown, true);
    });
    onBeforeUnmount(() => {
      document.removeEventListener("keydown", handleKeyDown, true);
    });

    return {
      currentWord,
      nextWord,
      playSound
    };
  }
});
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

img {
  height: 500px;
  margin-left: auto;
  margin-right: auto;
  max-height: 70vh;
  max-width: 100%;
  background-color: #ccc;
  border-radius: 6px;
  display: block;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.description {
  text-align: center;
  width: 90%;
  max-width: 300px;
  margin-top: 15px;
  line-height: 200%px;
  color: #666;

  p {
    margin-bottom: 5px;
  }
}
</style>
