
import { defineComponent } from "vue";
import { Table, TableItem, TableRow } from "@/components/table";
import { mapState } from "vuex";
import { State } from "@/store";

export default defineComponent({
  components: {
    Table,
    TableItem,
    TableRow
  },
  computed: {
    ...mapState({
      wordLists: (state: any) => state.wordLists
    })
  },
  methods: {
    editList(id: string) {
      this.$router.push(`/edit/${id}`);
    },
    practiceList(id: string) {
      this.$router.push(`/practice/${id}`);
    },
    routeToCreateList() {
      this.$router.push("/create");
    }
  }
});
