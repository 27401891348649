import _Form from "./Form.vue";
import _FormItem from "./FormItem.vue";
import _Label from "./Label.vue";
import _TextBox from "./TextBox.vue";
import _Dropdown from "./Dropdown.vue";

export const Form = _Form;
export const FormItem = _FormItem;
export const Label = _Label;
export const TextBox = _TextBox;
export const Dropdown = _Dropdown;
