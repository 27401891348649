
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true
    }
  }
});
