
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    },
    mdHeaders: {
      type: Array,
      default: null
    },
    lgHeaders: {
      type: Array,
      default: null
    }
    // empty: {
    //   type: Boolean,
    //   default: false
    // }
  }
});
